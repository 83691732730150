.asset-popup .leaflet-popup-content-wrapper {
    border-radius: 5px;
}

.asset-popup {
    bottom: -17px !important;
}

.asset-popup .leaflet-popup-tip {
    display: none;
}
.leaflet-popup-content {
    margin-top: 6px;
}