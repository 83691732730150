.map-controls-panel {
    text-align: left;
    border-radius: 10px;
    padding: 15px;
    background-color: #f5f5f5;
}

.map-controls-panel-heading {
    padding-bottom: 5px;
}

.map-controls-panel-heading-title {
    display: inline;
    padding-right: 5px;

}

.map-controls-panel-heading-button {
    display: inline;
}

.map-controls-panel-section {
    padding-bottom: 5px;
}

.map-controls-panel-section-title {
    margin: 0px;
}

.leaflet-draw-section {
	padding-left: 5px;
}

.leaflet-draw-toolbar.leaflet-bar.leaflet-draw-toolbar-top {
    border: 2px solid white;
	border-radius: 5px;
}
